import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App.tsx';
import store from './store/index.ts';
import '@app/locale';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
// <React.StrictMode>
_jsx(Provider, { store: store, children: _jsx(App, {}) }));
